export const officerColumnsDataOrders = [
  {
    Header: 'S.No',
    accessor: 'index',
    disableSortBy: true,
  },
  {
    Header: 'Officer Name',
    accessor: 'name',
    disableSortBy: true,
  },
  {
    Header: 'Company Name',
    accessor: 'customer.name',
    disableSortBy: true,
  },
  {
    Header: 'ACTIONS',
    accessor: 'COMPANY ACTIONS',
    disableSortBy: true,
  },
]
