import React, { useMemo, useState, useEffect } from 'react'
import { FiStar, FiTrash2, FiPlus, FiFolder, FiMail } from 'react-icons/fi'
import debounce from 'lodash.debounce'
import PaginationSkeleton from 'components/skeletons/PaginationSkeleton'
import SingleEmail from './AddAssignment'

function EmailInterface(props) {
  const {
    columnsData,
    tableData,
    totalCount,
    onPageChange,
    itemsPerPage,
    currentPage,
    isEmailLoading,
    setGlobalFilter,
  } = props

  const [data, setData] = useState([])
  const [totalPageCount, setPageCount] = useState(0)
  const [pageIndex, setPageIndex] = useState(currentPage)
  const [singleEmail, setSingleEmail] = useState(null) // null initially

  useEffect(() => {
    if (tableData) {
      setData(tableData.value)
    }
  }, [tableData])

  useEffect(() => {
    if (totalCount) {
      setPageCount(totalCount)
    }
  }, [totalCount])

  const tableSkeletons = Array.from({ length: 4 })

  const setFilter = debounce(async (value) => {
    try {
      setGlobalFilter(value)
    } catch (err) {
      console.log('err', err)
    }
  }, 500)

  const changePage = (page) => {
    setPageIndex(page)
    onPageChange(page + 1)
  }

  const openSingleEmail = (email) => {
    setSingleEmail(email) // Set the selected email
  }

  const goBackToEmailList = () => {
    setSingleEmail(null) // Clear selected email to go back to the list
  }

  return <div className="flex w-full"></div>
}

export default EmailInterface
