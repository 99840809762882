import React from 'react'
import EmployeeList from 'views/Employee/Employee'
// import CompanyAdd from 'views/CompanySetup/Company/components/AddCompany'
import FunctionList from 'views/Employee/Function'
import FunctionAdd from 'views/Employee/Function/components/AddFunction'
import GradeList from 'views/Employee/Grade'
import GradeAdd from 'views/Employee/Grade/components/AddGrade'
import BranchList from 'views/CompanySetup/Branch'
// import BranchAdd from 'views/CompanySetup/Branch/components/AddBranch'
import { FaFont, FaUser, FaTree } from 'react-icons/fa'
import { IoMdSettings } from 'react-icons/io'

const employeeRoutes = [
  {
    name: 'Employee',
    layout: '/afterLogin',
    path: '/employee/employee-list',
    icon: <FaUser className="text-inherit h-6 w-4" />,
    component: <EmployeeList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Employee',
    breadcrum: [
      { label: 'Employee', link: '', breadCrumDisabled: true },
      { label: 'List', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Function',
    layout: '/afterLogin',
    path: '/employee/function-list',
    icon: <IoMdSettings className="text-inherit h-6 w-4" />,
    component: <FunctionList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Employee',
    breadcrum: [
      { label: 'Employee', link: '', breadCrumDisabled: true },
      { label: 'Function', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Function',
    layout: '/afterLogin',
    path: '/employee/function-create',
    icon: <FaUser className="text-inherit h-6 w-6" />,
    component: <FunctionAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Employee',
    breadcrum: [
      { label: 'Employee', link: '', breadCrumDisabled: true },
      { label: 'Function', link: '/employee/function-list' },
      { label: 'Create', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Function',
    layout: '/afterLogin',
    path: '/employee/function-edit/:id',
    icon: <FaUser className="text-inherit h-6 w-6" />,
    component: <FunctionAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Employee',
    breadcrum: [
      { label: 'Employee', link: '', breadCrumDisabled: true },
      { label: 'Function', link: '/employee/function-list' },
      { label: 'Edit', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Grade',
    layout: '/afterLogin',
    icon: <FaFont className="text-inherit h-6 w-4" />,
    path: '/employee/grade-list',
    component: <GradeList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Employee',
    breadcrum: [
      { label: 'Employee', link: '', breadCrumDisabled: true },
      { label: 'Grade', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Grade',
    layout: '/afterLogin',
    path: '/employee/grade-create',
    icon: <FaUser className="text-inherit h-6 w-6" />,
    component: <GradeAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Employee',
    breadcrum: [
      { label: 'Employee', link: '', breadCrumDisabled: true },
      { label: 'Grade', link: '/employee/grade-list' },
      { label: 'Create', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Grade',
    layout: '/afterLogin',
    path: '/employee/grade-edit/:id',
    icon: <FaUser className="text-inherit h-6 w-6" />,
    component: <GradeAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Employee',
    breadcrum: [
      { label: 'Employee', link: '', breadCrumDisabled: true },
      { label: 'Grade', link: '/employee/grade-list' },
      { label: 'Edit', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Hierarchy',
    layout: '/afterLogin',
    icon: <FaTree className="text-inherit h-6 w-4" />,
    path: '/employee/hierarchy-list',
    component: <BranchList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Employee',
    breadcrum: [
      { label: 'Employee', link: '', breadCrumDisabled: true },
      { label: 'Hierarchy', link: '', breadCrumDisabled: true },
    ],
  },
]

export default employeeRoutes
