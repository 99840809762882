const baseUrl = process.env.REACT_APP_API_BASE_URL

const uploadUrl = process.env.REACT_APP_API_UPLOAD_URL
const readUrl = process.env.REACT_APP_API_READ_URL
const config = {
  READ_FILE_BASED_ON_ID: readUrl + 'api/upload/readFile?file=',
  NAVIGATION_COUNT_FOR_MENU: baseUrl + 'api/Employee/getNavigationCounts',

  // USER INFO
  GET_USER_INFO: baseUrl + 'api/user/profile',

  // LOCATION MODULE
  // Country
  COUNTRY_LIST: baseUrl + 'api/country/search',
  COUNTRY_CREATE: baseUrl + 'api/country/save',
  COUNTRY_DETAIL: baseUrl + 'api/country/getOne',

  // Zone
  ZONE_LIST: baseUrl + 'api/zone/search',
  ZONE_CREATE: baseUrl + 'api/zone/save',
  ZONE_DETAIL: baseUrl + 'api/zone/getOne',

  // State
  STATE_LIST: baseUrl + 'api/state/search',
  STATE_CREATE: baseUrl + 'api/state/save',
  STATE_DETAIL: baseUrl + 'api/state/getOne',

  // District
  DISTRICT_LIST: baseUrl + 'api/district/search',
  DISTRICT_CREATE: baseUrl + 'api/district/save',
  DISTRICT_DETAIL: baseUrl + 'api/district/getOne',

  // City
  CITY_LIST: baseUrl + 'api/city/search',
  CITY_CREATE: baseUrl + 'api/city/save',
  CITY_DETAIL: baseUrl + 'api/city/getOne',

  // EMAIL
  EMAIL_LIST: baseUrl + 'api/User/listOutlookEmail',
  EMAIL_PDF: baseUrl + 'api/User/generateEmailPdf',
  EMAIL_ATTACHMENTS: baseUrl + 'api/User/getEmailAttachments',

  // COMPANY SETUP MODULE
  // Company
  COMPANY_LIST: baseUrl + 'api/company/search',
  COMPANY_CITY_LIST: baseUrl + 'api/city/populateCityDetails',
  CURRENCY_LIST: baseUrl + 'api/currencies/search',
  BANK_LIST: baseUrl + 'api/bank/search',
  COMPANY_GST_LIST: baseUrl + 'api/customerCompany/getGSTINDetails',
  COMPANY_DETAIL: baseUrl + 'api/company/getOne',
  COMPANY_CREATE: baseUrl + 'api/company/save',
  UPLOAD_IMAGE: uploadUrl + 'api/upload',

  // Type Of Office
  TYPE_OF_OFFICE_LIST: baseUrl + 'api/typeOfOffice/search',
  TYPE_OF_OFFICE_CREATE: baseUrl + 'api/typeOfOffice/save',
  TYPE_OF_OFFICE_DETAIL: baseUrl + 'api/typeOfOffice/getOne',

  // Office
  OFFICE_LIST: baseUrl + 'api/office/search',
  OFFICE_CREATE: baseUrl + 'api/office/save',
  OFFICE_DETAIL: baseUrl + 'api/office/getOne',

  // Branch
  BRANCH_LIST: baseUrl + 'api/branch/search',
  BRANCH_CREATE: baseUrl + 'api/branch/save',
  BRANCH_DETAIL: baseUrl + 'api/branch/getOneBranch',

  // CUSTOMERS
  // Segment
  SEGMENT_LIST: baseUrl + 'api/CustomerSegment/search',
  SEGMENT_DETAIL: baseUrl + 'api/CustomerSegment/getOne',
  SEGMENT_CREATE: baseUrl + 'api/CustomerSegment/save',

  // Company
  CUSTOMER_COMPANY_LIST: baseUrl + 'api/CustomerCompany/search',

  // Customer
  CUSTOMER_LIST: baseUrl + 'api/customer/search',

  // Officer
  OFFICER_LIST: baseUrl + 'api/officer/search',

  // EMPLOYEE
  // Employee
  EMPLOYEE_LIST: baseUrl + 'api/Employee/search',

  // Function
  FUNCTION_LIST: baseUrl + 'api/Func/search',
  FUNCTION_DETAIL: baseUrl + 'api/Func/getOne',
  FUNCTION_CREATE: baseUrl + 'api/Func/save',

  // Grade
  GRADE_LIST: baseUrl + 'api/Grade/search',
  GRADE_DETAIL: baseUrl + 'api/Grade/getOne',
  GRADE_CREATE: baseUrl + 'api/Grade/save',
}
export default config
