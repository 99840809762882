import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FiChevronLeft } from 'react-icons/fi'
import { MdOutlineAttachEmail } from 'react-icons/md'
import moment from 'moment'
import { getEmailAttachments } from 'common/commonFunction'

const SingleEmailView = ({ email }) => {
  const [emailAttachment, setEmailAttachment] = useState({})
  const [isEmailLoading, setEmailLoading] = useState(false)
  const [assignmentBtn, setAssignmentBtn] = useState(false)
  const navigate = useNavigate()

  const createAssignment = () => {
    // Logic for creating an assignment with email date
  }

  const fetchAttachments = async (id) => {
    try {
      setEmailLoading(true)
      const payload = { messageId: id }
      const getEmailAttachment = await getEmailAttachments(payload)
      if (getEmailAttachment?.data?.value) {
        setEmailAttachment(
          getEmailAttachment?.data?.data?.emailAttachments.value
        )
      } else {
        setEmailAttachment({})
      }
    } catch (error) {
      console.error('Error fetching attachments:', error)
    } finally {
      setEmailLoading(false)
    }
  }

  const openAttachment = (attachment) => {
    const byteCharacters = atob(attachment.contentBytes)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)

    const fileBlob = new Blob([byteArray], {
      type: attachment.contentType,
    })
    const url = window.URL.createObjectURL(fileBlob)

    if (attachment.contentType === 'text/html') {
      window.open(url, '_blank')
    } else {
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', attachment.name)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  return (
    <div className={`${assignmentBtn ? 'hidden' : 'block'}`}>
      <div className="flex">
        <div className="w-full">
          <div className="mb-4">
            <h2 className="text-2xl font-bold">{email.subject}</h2>
          </div>
        </div>
      </div>

      <div className="rounded-lg bg-white p-6 shadow">
        <div className="flex flex-col space-y-4">
          <div className={`${assignmentBtn ? 'hidden' : 'block'}`}>
            <div className="space-y-6">
              <div className="mb-4 flex items-center justify-between border-b pb-4">
                <div>
                  <h3 className="text-lg font-semibold">
                    {email.sender.emailAddress.name}
                  </h3>
                  <p className="text-sm text-gray-600">
                    To: {email.toRecipients[0].emailAddress.name} (
                    {email.toRecipients[0].emailAddress.address})
                  </p>
                </div>

                <div className="flex space-x-2">
                  {email.hasAttachments ? (
                    <button className="btn btn-default disabled rounded-md bg-gray-100 px-4 py-2 text-gray-700 hover:bg-gray-200">
                      <MdOutlineAttachEmail />
                    </button>
                  ) : (
                    <></>
                  )}
                  <button
                    className="btn btn-default rounded-md bg-gray-100 px-4 py-2 text-gray-700 hover:bg-gray-200"
                    onClick={() => navigate(`/assignment/create/${email.id}`)}
                  >
                    Create Assignment
                  </button>
                  <button
                    className="btn btn-default rounded-md bg-gray-100 px-4 py-2 text-gray-700 hover:bg-gray-200"
                    onClick={() => navigate('/inbox')}
                  >
                    <FiChevronLeft />
                  </button>
                </div>
              </div>

              <div className="rounded-lg bg-gray-50 p-4">
                <p>
                  <b>Date</b>:{' '}
                  {moment(email?.sentDateTime).format('MM/DD/YY HH:SS:ss A')}
                  <b> Subject</b>: {email.subject} <br />
                  <b>From</b>: {email.sender.emailAddress.name} (
                  {email.sender.emailAddress.address}) <br />
                  <b>To</b>: {email.toRecipients[0].emailAddress.name} (
                  {email.toRecipients[0].emailAddress.address}) <br />
                  {email.ccRecipients.length > 0 && (
                    <>
                      <b>Cc</b>:{' '}
                      {email.ccRecipients.map((cc, index) => (
                        <span key={index}>
                          {cc.emailAddress.name} ({cc.emailAddress.address}){' '}
                        </span>
                      ))}{' '}
                      <br />
                    </>
                  )}
                </p>
                <div dangerouslySetInnerHTML={{ __html: email.body.content }} />
              </div>

              {/* Fetch and Display Attachments */}
              {email.hasAttachments && (
                <div className="mt-4">
                  {!emailAttachment.length && (
                    <button
                      className="btn btn-primary"
                      onClick={() => fetchAttachments(email.id)}
                    >
                      {isEmailLoading ? 'Loading...' : 'Load Attachments'}
                    </button>
                  )}
                  {isEmailLoading && (
                    <div className="mt-4 p-4 text-center text-gray-600">
                      <p>Loading attachments...</p>
                    </div>
                  )}

                  {!isEmailLoading && emailAttachment.length > 0 && (
                    <div className="mt-4 flex flex-wrap gap-2">
                      {emailAttachment.map((attachment) => (
                        <div
                          key={attachment.id}
                          className="w-full cursor-pointer rounded-lg border p-2 shadow-sm hover:shadow-md md:w-1/4"
                          onClick={() => openAttachment(attachment)}
                        >
                          <MdOutlineAttachEmail className="text-2xl" />
                          <p className="mt-2 break-words text-sm font-semibold">
                            {attachment.name}
                          </p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          {assignmentBtn && (
            <div className="text-center">
              <h4 className="text-lg font-semibold">Relogin Required</h4>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SingleEmailView
