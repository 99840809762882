import React from 'react'
import SegmentList from 'views/Customers/Segment'
import SegmentAdd from 'views/Customers/Segment/components/AddSegment'
import CompanyList from 'views/Customers/Company'
import CustomerList from 'views/Customers/Customer'
import OfficerList from 'views/Customers/Officer'
import { FaBuilding, FaUser } from 'react-icons/fa'

const customersRoutes = [
  {
    name: 'Segment',
    layout: '/afterLogin',
    path: '/customers/segment-list',
    icon: <FaUser className="text-inherit h-6 w-4" />,
    component: <SegmentList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Customers',
    breadcrum: [
      { label: 'Customers', link: '', breadCrumDisabled: true },
      { label: 'Segment', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Segment',
    layout: '/afterLogin',
    path: '/customers/segment-create',
    icon: <FaUser className="text-inherit h-6 w-6" />,
    component: <SegmentAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Customers',
    breadcrum: [
      { label: 'Customers', link: '', breadCrumDisabled: true },
      { label: 'Segment', link: '/customers/segment-list' },
      { label: 'Create', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Segment',
    layout: '/afterLogin',
    path: '/customers/segment-edit/:id',
    icon: <FaUser className="text-inherit h-6 w-6" />,
    component: <SegmentAdd />,
    isComing: false,
    isVisible: false,
    parentTitle: 'Customers',
    breadcrum: [
      { label: 'Customers', link: '', breadCrumDisabled: true },
      { label: 'Segment', link: '/customers/segment-list' },
      { label: 'Edit', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Company',
    layout: '/afterLogin',
    path: '/customers/company-list',
    icon: <FaBuilding className="text-inherit h-6 w-4" />,
    component: <CompanyList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Customers',
    breadcrum: [
      { label: 'Customers', link: '', breadCrumDisabled: true },
      { label: 'Company', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Customer',
    layout: '/afterLogin',
    icon: <FaUser className="text-inherit h-6 w-4" />,
    path: '/customers/customer-list',
    component: <CustomerList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Customers',
    breadcrum: [
      { label: 'Customers', link: '', breadCrumDisabled: true },
      { label: 'Customer', link: '', breadCrumDisabled: true },
    ],
  },
  {
    name: 'Officer',
    layout: '/afterLogin',
    icon: <FaUser className="text-inherit h-6 w-4" />,
    path: '/customers/officer-list',
    component: <OfficerList />,
    isComing: false,
    isVisible: true,
    parentTitle: 'Customers',
    breadcrum: [
      { label: 'Customers', link: '', breadCrumDisabled: true },
      { label: 'Officer', link: '', breadCrumDisabled: true },
    ],
  },
]

export default customersRoutes
