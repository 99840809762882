import ApiCaller from 'common/services/apiServices'
import apiConfig from 'common/config/apiConfig'
import CryptoJS from 'crypto-js'
const apiService = ApiCaller()
/**
 * Get all application data from the API.
 *
 * @return {Promise} The response from the API call.
 */

/**
 * Retrieves all application data from the API.
 *
 * @param {number} page - The page number of the data to retrieve.
 * @param {string} [search=''] - An optional search query to filter the data.
 * @return {Promise} A promise that resolves to the response from the API call.
 */
const getAllApplicationData = async (page, search = '') => {
  try {
    const projectId = getProjectData()
    let apiUrl = apiConfig.GET_ALL_APPLICATION + `/${projectId}/${page}`
    if (search) {
      apiUrl = apiUrl + `/${search}`
    }
    const response = await apiService.apiCall('get', apiUrl)
    return response
  } catch (error) {
    console.log(error)
  }
}
/**
 * Retrieves all projects data based on page and optional search term.
 *
 * @param {number} page - The page number to retrieve projects data from.
 * @param {string} [search=''] - The optional search term to filter projects.
 * @return {Promise} The response containing the projects data.
 */
const getAllProjectsData = async (page, search = '') => {
  const organizationData = JSON.parse(localStorage.getItem('organizationData'))
  try {
    let apiUrl = apiConfig.GET_ALL_PROJECTS + `/${organizationData?.id}/${page}`
    if (search) {
      apiUrl = apiUrl + `/${search}`
    }
    const response = await apiService.apiCall('get', apiUrl)
    return response
  } catch (error) {
    console.log(error)
  }
}

const getUserInformations = async () => {
  try {
    const apiUrl = apiConfig.GET_USER_INFO
    const payload = {}
    const response = await apiService.apiCall('post', apiUrl, payload)
    return response
  } catch (error) {
    console.log(error)
  }
}

/**
 * Stores the user information in the local storage.
 *
 * @return {Promise<void>} A promise that resolves when the user information is successfully stored.
 */
const storeUserInfo = async () => {
  try {
    const response = await getUserInformations()
    console.log('n1.....', response)
    if (response.status === 200) {
      localStorage.setItem('userData', JSON.stringify(response?.data?.data))
    }
  } catch (error) {
    console.log('getUserInfo Error', error)
  }
}

/**
 * Retrieves artifact details by making an asynchronous API call.
 *
 * @return {Promise} The response containing the artifact details.
 */
const getArtifactDetails = async () => {
  const organizationData = JSON.parse(localStorage.getItem('organizationData'))
  try {
    const apiUrl = apiConfig.GET_ARTIFACT_DATA.replace(
      ':organization_id',
      organizationData?.id
    )
    const response = await apiService.apiCall('get', apiUrl)
    return response
  } catch (error) {
    console.log(error)
  }
}

/**
 * Encrypts the given word using AES algorithm with an optional key.
 *
 * @param {string} word - The word to be encrypted.
 * @param {string} [key='secret'] - The key used for encryption.
 * @return {string} The encrypted data in Base64 format.
 */
const encryptId = (word, key = 'secret') => {
  let encJson = CryptoJS.AES.encrypt(JSON.stringify(word), key).toString()
  let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson))
  return encData
}

/**
 * Converts an array of objects into an array of options objects.
 *
 * @param {Array<Object>} arrayOfObjects - The array of objects to be converted.
 * @return {Array<Object>} The array of options objects.
 */
const convertToOptions = (arrayOfObjects) => {
  const options = arrayOfObjects.map((obj) => ({
    value: obj.full_name,
    label: obj.full_name,
  }))

  return options
}

/**
 * Retrieves organization details by making an asynchronous API call.
 *
 * @return {Promise} The response containing the organization details.
 */
const getOrganization = async () => {
  try {
    const apiUrl = apiConfig.GET_ORGANIZATION_DETAILS
    const response = await apiService.apiCall('get', apiUrl)
    return response
  } catch (error) {
    console.log(error)
  }
}

/**
 * Retrieves project data by getting the project ID from localStorage.
 *
 * @return {string} The project ID retrieved from localStorage.
 */
const getProjectData = () => {
  const projectId = localStorage.getItem('projectId')
  return projectId
}

/**
 * Sets the repository name in the local storage by converting the provided repository data to a JSON string
 * and removing any double quotes.
 *
 * @param {Object} repoData - The repository data to be stored.
 * @return {void} This function does not return anything.
 */
const setRepoName = (repoData) => {
  localStorage.setItem(
    'repoFullName',
    JSON.stringify(repoData).replace(/"/g, '')
  )
}

/**
 * Retrieves the repository name from local storage.
 *
 * @return {string} The repository name retrieved from local storage.
 */
const getRepoName = () => {
  return localStorage.getItem('repoFullName')
}

const handleLogout = async () => {
  try {
    const apiUrl = apiConfig.LOGOUT_USER
    const payload = {}
    const response = await apiService.apiCall('post', apiUrl, payload)
    if (response.status === 200) {
      localStorage.clear()
      return
    }
  } catch (error) {
    localStorage.clear()
    console.error('Error during API call:', error)
  }
}

const toKebabCase = (str) => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1-$2') // Handle camelCase
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/_/g, '-') // Replace underscores with -
    .toLowerCase() // Convert to lower case
}

const getCountry = async (payload) => {
  const apiUrl = apiConfig.COUNTRY_LIST
  const response = await apiService.apiCall('post', apiUrl, payload)
  return response
}

const getZone = async (payload) => {
  const apiUrl = apiConfig.ZONE_LIST
  const response = await apiService.apiCall('post', apiUrl, payload)
  return response
}

const getState = async (payload) => {
  const apiUrl = apiConfig.STATE_LIST
  const response = await apiService.apiCall('post', apiUrl, payload)
  return response
}

const getDistrict = async (payload) => {
  const apiUrl = apiConfig.DISTRICT_LIST
  const response = await apiService.apiCall('post', apiUrl, payload)
  return response
}

const getCity = async (payload) => {
  const apiUrl = apiConfig.CITY_LIST
  const response = await apiService.apiCall('post', apiUrl, payload)
  return response
}

const getEmail = async (payload) => {
  const apiUrl = apiConfig.EMAIL_LIST
  const response = await apiService.apiCall('post', apiUrl, payload)
  return response
}

const getEmailPdf = async (payload) => {
  const apiUrl = apiConfig.EMAIL_PDF
  const response = await apiService.apiCall('post', apiUrl, payload)
  return response
}

const getEmailAttachments = async (payload) => {
  const apiUrl = apiConfig.EMAIL_ATTACHMENTS
  const response = await apiService.apiCall('post', apiUrl, payload)
  return response
}

const readFileBasedOnId = (id) => {
  return `${apiConfig.READ_FILE_BASED_ON_ID}${id}`
}

const pdfGoogleViewer = async (readUrl, pdfName) => {
  console.log('getEmailPDF', readUrl, pdfName)
  if (readUrl) {
    const response = await apiService.apiCall(
      'get',
      `${readUrl}api/upload/readFile?file=${pdfName}&iframe=true`,
      {}
    )

    // console.log(response.data)
    return response
    // if (response?.data?.s3Url) {
    //   return response?.data?.s3Url
    //   // return `https://docs.google.com/viewerng/viewer?url=${response?.data?.s3Url}`
    // }
  } else {
    return 'img/placeholder.png'
  }
}

export {
  getAllApplicationData,
  encryptId,
  getUserInformations,
  getArtifactDetails,
  convertToOptions,
  getAllProjectsData,
  getOrganization,
  getProjectData,
  storeUserInfo,
  setRepoName,
  getRepoName,
  toKebabCase,
  handleLogout,
  getCountry,
  getZone,
  getState,
  getDistrict,
  getCity,
  getEmail,
  getEmailPdf,
  getEmailAttachments,
  readFileBasedOnId,
  pdfGoogleViewer,
}
