import React, { useState, useEffect } from 'react'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import Card from 'components/card'
import { useForm, Controller } from 'react-hook-form'
import { useToaster } from 'common/Toaster'
import CrawfordConstants from 'common/config/crawfordConstants'
import { useSpinner } from 'common/SpinnerLoader'
import { useParams, useNavigate } from 'react-router-dom'
import InputField from 'components/fields/InputField'
import Select from 'react-select'
import { getCountry, getZone } from 'common/commonFunction'
const apiService = ApiCaller()
const { TOAST } = CrawfordConstants

/**
 * Renders a form to add or edit a zone.
 *
 * @return {JSX.Element} The rendered form component.
 */
const AddState = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      COUNTRY: '',
      ZONE: '',
      STATE: '',
      STATE_CODE: '',
    },
  })

  const { addToast } = useToaster()
  const { showSpinner, hideSpinner } = useSpinner()

  const [options, setOptions] = useState([])
  const [zoneOptions, setZoneOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState('')
  // Fetch data from API
  const fetchOptions = async (inputValue) => {
    setIsLoading(true)
    try {
      const payload = {
        keyword: inputValue ? inputValue : '',
        filter: {},
        fields: [],
        page: inputValue ? 1 : 0,
      }
      const getCountryList = await getCountry(payload)
      if (getCountryList?.data?.value) {
        const newOptions = getCountryList?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
          ZONE: item.zone,
        }))
        setOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  // Handle input change and trigger API call
  const handleInputChange = (inputValue) => {
    if (inputValue.length > 2) {
      fetchOptions(inputValue)
    }
  }

  const handleSelectionChange = (COUNTRY) => {
    if (!COUNTRY) {
      fetchOptions() // Call the function to perform API request
      setZoneOptions([])
    } else {
      setSelectedCountry(COUNTRY.value)
    }
  }

  // Fetch data from API
  const fetchZoneOptions = async (inputValue) => {
    try {
      setIsLoading(true)
      const payload = {
        keyword: inputValue ? inputValue : '',
        filter: { country: selectedCountry },
        fields: [],
        page: inputValue ? 1 : 0,
      }
      const getZoneList = await getZone(payload)
      if (getZoneList?.data?.value) {
        const newOptions = getZoneList?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setZoneOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleZoneInputChange = (inputValue) => {
    if (inputValue.length > 2) {
      fetchZoneOptions(inputValue)
    }
  }

  const handleZoneSelectionChange = (ZONE) => {
    if (!ZONE) {
      fetchOptions() // Call the function to perform API request
    } else {
      console.log('Selected option:', ZONE)
    }
  }

  useEffect(() => {
    fetchZoneOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry])

  /**
   * Handles the form submission for creating a new zone.
   *
   * @async
   * @function onFormSubmit
   * @param {Object} formData - The form data containing the zone details.
   * @param {string} formData.country - The id of the country.
   * @param {string} formData.name - The name of the zone.
   * @returns {Promise<void>} A promise that resolves when the form submission is complete.
   */
  const onFormSubmit = async (formData) => {
    try {
      showSpinner()
      const apiUrl = apiConfig.STATE_CREATE
      const payload = {
        country: formData.COUNTRY.value,
        zone: formData.ZONE.value,
        name: formData.STATE,
        stateCode: formData.STATE_CODE,
        _id: id ? id : undefined,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        addToast({
          title: `State "${formData.STATE}" ${
            id ? 'edited' : 'created'
          } successfully.`,
          type: 'success',
        })
        navigate(`/locations/state-list`)
      } else {
        addToast({
          title: `State creation failed.`,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  /**
   * Fetches the details of a specific state and sets the form values.
   *
   * @async
   * @function getStateDetails
   * @returns {Promise<void>} A promise that resolves when the state details are fetched and the form values are set.
   */
  const getStateDetails = async () => {
    try {
      showSpinner()
      const apiUrl = apiConfig.STATE_DETAIL.replace(':id', id)
      const payload = {
        _id: id,
        checkRoles: false,
        accessOf: ['stateEdit'],
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response.data === 'Access Denied') {
        addToast({
          title: 'Access Denied',
          type: 'error',
        })
        navigate(`/locations/state-list`)
      } else if (response?.data?.value) {
        setValue(
          'COUNTRY',
          {
            label: response?.data?.data?.zone?.country?.name,
            value: response?.data?.data?.zone?.country?._id,
          } || {}
        )
        setSelectedCountry(response?.data?.data?.zone?.country?._id)
        fetchOptions(response?.data?.data?.zone?.country?.name)
        setValue(
          'ZONE',
          {
            label: response?.data?.data?.zone?.name,
            value: response?.data?.data?.zone?._id,
          } || {}
        )
        setValue('STATE', response?.data?.data?.name || '')
        setValue('STATE_CODE', response?.data?.data?.stateCode || '')
      } else {
        addToast({
          title: 'Some Error Occured',
          type: 'error',
        })
      }
    } catch (error) {
      console.error('Error verifying token:', error)
    } finally {
      hideSpinner()
    }
  }

  const handleCancel = () => {
    navigate(`/locations/state-list`)
  }

  useEffect(() => {
    if (id) {
      getStateDetails()
    }
    fetchOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card extra={'w-full h-full pb-[35px] pt-[3px] pr-[28px] pl-[33px]'}>
      <div className="mt-[25px]">
        <p className="text-xl font-bold text-navy-700 dark:text-white">
          {id ? 'Edit' : 'Add'} State
        </p>
      </div>

      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="mt-7 grid grid-cols-1 gap-3">
          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold">
              Country <span className="text-red-500">*</span>
            </label>
            <Controller
              name="COUNTRY"
              control={control}
              rules={{ required: 'Country is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  options={options}
                  placeholder="Search and select..."
                  classNamePrefix="select"
                  isClearable
                  isLoading={isLoading}
                  onInputChange={handleInputChange}
                  noOptionsMessage={() => 'Type to search...'}
                  onChange={(COUNTRY) => {
                    field.onChange(COUNTRY)
                    handleSelectionChange(COUNTRY) // Call a function to handle API call
                    if (!COUNTRY) {
                      // Reset ZONE dropdown when COUNTRY is reset
                      setValue('ZONE', null) // Clear the ZONE field in the form
                    }
                  }}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Red border on error
                      minHeight: '44px', // Increase height
                      borderRadius: '0.75rem', // Border radius
                      boxShadow: state.isFocused
                        ? `0 0 0 1px ${
                            error ? 'red' : 'rgba(218, 222, 236, 1)'
                          }` // Red shadow on focus if error
                        : '',
                      '&:hover': {
                        borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Red border on hover if error
                      },
                    }),
                    placeholder: (base) => ({
                      ...base,
                      fontWeight: 'normal',
                      fontSize: '14px',
                      color: error ? 'red' : '#999', // Red placeholder color on error, default gray otherwise
                    }),
                  }}
                />
              )}
            />
            {errors.COUNTRY && (
              <div className="error-message text-right text-red-500">
                {errors.COUNTRY.message}
              </div>
            )}
          </div>

          <div className="col-span-2 lg:col-span-1">
            <label className="mb-2 block text-sm font-bold">
              Zone <span className="text-red-500">*</span>
            </label>
            <Controller
              name="ZONE"
              control={control}
              rules={{ required: 'Zone is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  options={zoneOptions}
                  placeholder="Search and select..."
                  classNamePrefix="select"
                  isClearable
                  isLoading={isLoading}
                  onInputChange={handleZoneInputChange}
                  noOptionsMessage={() => 'Type to search...'}
                  onChange={(ZONE) => {
                    field.onChange(ZONE)
                    handleZoneSelectionChange(ZONE) // Call a function to handle API call
                  }}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Red border on error
                      minHeight: '44px', // Increase height
                      borderRadius: '0.75rem', // Border radius
                      boxShadow: state.isFocused
                        ? `0 0 0 1px ${
                            error ? 'red' : 'rgba(218, 222, 236, 1)'
                          }` // Red shadow on focus if error
                        : '',
                      '&:hover': {
                        borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Red border on hover if error
                      },
                    }),
                    placeholder: (base) => ({
                      ...base,
                      fontWeight: 'normal',
                      fontSize: '14px',
                      color: error ? 'red' : '#999', // Red placeholder color on error, default gray otherwise
                    }),
                  }}
                />
              )}
            />
            {errors.ZONE && (
              <div className="error-message text-right text-red-500">
                {errors.ZONE.message}
              </div>
            )}
          </div>

          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="w-full"
              label="State"
              id="state"
              type="text"
              placeholder="Enter state name"
              registrationProps={register('STATE', {
                required: 'State is required',
              })}
              isFieldRequired={true}
              state={errors.STATE && 'error'}
            />
            <div className="error-message text-right">
              {errors.STATE?.message}
            </div>
          </div>

          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="w-full"
              label="State Code"
              id="STATE_CODE"
              type="text"
              placeholder="Enter state code name"
              registrationProps={register('STATE_CODE', {})}
            />
          </div>

          {/* Button aligned to the right */}
          <div className="flex w-full justify-end">
            <button
              className={`mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200
          hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base`}
              type="submit"
            >
              <span>{id ? 'Update' : 'Save'}</span>
            </button>

            <button
              className={`ml-2 mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-gray-400 p-3 text-sm text-white transition duration-200
          hover:cursor-pointer hover:bg-gray-600 active:bg-gray-700 md:text-base`}
              type="button"
              onClick={handleCancel}
            >
              <span>Cancel</span>
            </button>
          </div>
        </div>
      </form>
    </Card>
  )
}

export default AddState
