import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import Card from 'components/card'
import { useForm } from 'react-hook-form'
import { useEffect } from 'react'
import { useToaster } from 'common/Toaster'
import CrawfordConstants from 'common/config/crawfordConstants'
import { useSpinner } from 'common/SpinnerLoader'
import { useParams, useNavigate } from 'react-router-dom'
import InputField from 'components/fields/InputField'
const apiService = ApiCaller()
const { TOAST } = CrawfordConstants

/**
 * Renders a form to add or edit a country.
 *
 * @return {JSX.Element} The rendered form component.
 */
const AddTypeOfOffice = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      OfficeType: '',
      ShortCode: '',
    },
  })
  const { addToast } = useToaster()
  const { showSpinner, hideSpinner } = useSpinner()

  /**
   * Handles the form submission for creating or editing an office type.
   *
   * This function submits form data to the server, either creating a new office type
   * or editing an existing one based on the presence of an ID. It also shows success
   * or error notifications based on the server response.
   *
   * @async
   * @function onFormSubmit
   * @param {Object} formData - The data submitted from the form.
   * @param {string} formData.OfficeType - The name of the office type.
   * @param {string} formData.ShortCode - The short code of the office type.
   * @returns {Promise<void>} A promise that resolves when the form submission is complete.
   * @throws Will throw an error if the API call fails.
   */
  const onFormSubmit = async (formData) => {
    try {
      showSpinner()
      const apiUrl = apiConfig.TYPE_OF_OFFICE_CREATE
      const payload = {
        name: formData.OfficeType,
        shortCode: formData.ShortCode,
        _id: id ? id : undefined,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        addToast({
          title: `Office type "${formData.OfficeType}" ${
            id ? 'edited' : 'created'
          } successfully.`,
          type: 'success',
        })
        navigate(`/company-setup/typeOfOffice-list`)
      } else {
        addToast({
          title: `Office type creation failed.`,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  /**
   * Fetches the details of a specific office type and sets the form values.
   *
   * This function retrieves the details of an office type using its ID,
   * checks for access permissions, and populates form fields with the retrieved data.
   * If access is denied or an error occurs, appropriate notifications are displayed.
   *
   * @async
   * @function getOfficeTypeDetails
   * @returns {Promise<void>} A promise that resolves when the office type details are fetched
   * and form values are set, or when navigation or error handling is completed.
   * @throws Will throw an error if the API call fails.
   */
  const getOfficeTypeDetails = async () => {
    try {
      showSpinner()
      const apiUrl = apiConfig.TYPE_OF_OFFICE_DETAIL.replace(':id', id)
      const payload = {
        _id: id,
        checkRoles: false,
        accessOf: ['typeOfOfficeEdit'],
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response.data === 'Access Denied') {
        addToast({
          title: 'Access Denied',
          type: 'error',
        })
        navigate(`/company-setup/typeOfOffice-list`)
      } else if (response?.data?.value) {
        setValue('OfficeType', response?.data?.data?.name || '')
        setValue('ShortCode', response?.data?.data?.shortCode || '')
      } else {
        addToast({
          title: 'Some Error Occured',
          type: 'error',
        })
      }
    } catch (error) {
      console.error('Error verifying token:', error)
    } finally {
      hideSpinner()
    }
  }

  const handleCancel = () => {
    navigate(`/company-setup/typeOfOffice-list`)
  }

  useEffect(() => {
    if (id) {
      getOfficeTypeDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card extra={'w-full h-full pb-[35px] pt-[3px] pr-[28px] pl-[33px]'}>
      <div className="mt-[25px]">
        <p className="text-xl font-bold text-navy-700 dark:text-white">
          {id ? 'Edit' : 'Add'} Office Type
        </p>
      </div>

      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="mt-7 grid grid-cols-1 gap-3">
          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="w-full"
              label="Office Type"
              id="OfficeType"
              type="text"
              placeholder="Enter office type"
              registrationProps={register('OfficeType', {
                required: 'Office type is required',
              })}
              isFieldRequired={true}
              state={errors.OfficeType && 'error'}
            />
            <div className="error-message text-right">
              {errors.OfficeType?.message}
            </div>
          </div>

          <div className="col-span-2 lg:col-span-1">
            <InputField
              extra="w-full"
              label="Short code"
              id="ShortCode"
              type="text"
              placeholder="Enter short code"
              registrationProps={register('ShortCode', {
                required: 'Short code is required',
              })}
              isFieldRequired={true}
              state={errors.ShortCode && 'error'}
            />
            <div className="error-message text-right">
              {errors.ShortCode?.message}
            </div>
          </div>

          {/* Button aligned to the right */}
          <div className="flex w-full justify-end">
            <button
              className={`mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200
          hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base`}
              type="submit"
            >
              <span>{id ? 'Update' : 'Save'}</span>
            </button>

            <button
              className={`ml-2 mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-gray-400 p-3 text-sm text-white transition duration-200
          hover:cursor-pointer hover:bg-gray-600 active:bg-gray-700 md:text-base`}
              type="button"
              onClick={handleCancel}
            >
              <span>Cancel</span>
            </button>
          </div>
        </div>
      </form>
    </Card>
  )
}

export default AddTypeOfOffice
