import React, { useState, useEffect } from 'react'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import Card from 'components/card'
import { useForm, Controller } from 'react-hook-form'
import { useToaster } from 'common/Toaster'
import CrawfordConstants from 'common/config/crawfordConstants'
import { useSpinner } from 'common/SpinnerLoader'
import { useParams, useNavigate } from 'react-router-dom'
import InputField from 'components/fields/InputField'
import Select from 'react-select'
import SwitchWithText from 'components/switch-with-text'
import TimezoneSelect from 'react-timezone-select'
import {
  getCountry,
  getZone,
  getState,
  getDistrict,
} from 'common/commonFunction'
const apiService = ApiCaller()
const { TOAST } = CrawfordConstants

/**
 * Renders a form to add or edit a district.
 *
 * @return {JSX.Element} The rendered form component.
 */
const AddAssignment = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      COUNTRY: '',
      ZONE: '',
      STATE: '',
      DISTRICT: '',
      CITY: '',
      STD_CODE: '',
      TIME_ZONE: '',
    },
  })

  const [selectedTimezone, setSelectedTimezone] = useState()

  const { addToast } = useToaster()
  const { showSpinner, hideSpinner } = useSpinner()

  const [options, setOptions] = useState([])
  const [zoneOptions, setZoneOptions] = useState([])
  const [stateOptions, setStateOptions] = useState([])
  const [districtOptions, setDistrictOptions] = useState([])

  const [isLoading, setIsLoading] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedZone, setSelectedZone] = useState('')
  const [selectedState, setSelectedState] = useState('')

  const [isBranchActive, setBranchStatus] = useState(false)
  const [isBranchBillable, setBranchBillable] = useState(false)

  const timezoneOptions = [
    { value: '-12', label: '(GMT-12:00) International Date Line West' },
    { value: '-11', label: '(GMT-11:00) Midway Island, Samoa' },
    { value: '-10', label: '(GMT-10:00) Hawaii' },
    { value: '-9', label: '(GMT-09:00) Alaska' },
    { value: '-8', label: '(GMT-08:00) Pacific Time (US & Canada)' },
    { value: '-7', label: '(GMT-07:00) Arizona' },
    { value: '-6', label: '(GMT-06:00) Central America' },
    { value: '-5', label: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco' },
    { value: '-4', label: '(GMT-04:00) Atlantic Time (Canada)' },
    { value: '-3.5', label: '(GMT-03:30) Newfoundland' },
    { value: '-3', label: '(GMT-03:00) Brasilia' },
    { value: '-2', label: '(GMT-02:00) Mid-Atlantic' },
    { value: '-1', label: '(GMT-01:00) Cape Verde Is.' },
    { value: '0', label: '(GMT+00:00) Casablanca, Monrovia, Reykjavik' },
    {
      value: '1',
      label: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    },
    { value: '2', label: '(GMT+02:00) Amman' },
    { value: '3', label: '(GMT+03:00) Kuwait, Riyadh, Baghdad' },
    { value: '3.5', label: '(GMT+03:30) Tehran' },
    { value: '4', label: '(GMT+04:00) Abu Dhabi, Muscat' },
    { value: '4.5', label: '(GMT+04:30) Kabul' },
    { value: '5', label: '(GMT+05:00) Yekaterinburg' },
    { value: '5.5', label: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi' },
    { value: '5.75', label: '(GMT+05:45) Kathmandu' },
    { value: '6', label: '(GMT+06:00) Almaty, Novosibirsk' },
    { value: '6.5', label: '(GMT+06:30) Yangon (Rangoon)' },
    { value: '7', label: '(GMT+07:00) Bangkok, Hanoi, Jakarta' },
    { value: '8', label: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi' },
    { value: '9', label: '(GMT+09:00) Osaka, Sapporo, Tokyo' },
    { value: '9.5', label: '(GMT+09:30) Adelaide' },
    { value: '10', label: '(GMT+10:00) Brisbane' },
    { value: '11', label: '(GMT+11:00) Magadan, Solomon Is., New Caledonia' },
    { value: '12', label: '(GMT+12:00) Auckland, Wellington' },
    { value: '13', label: "(GMT+13:00) Nuku'alofa" },
  ]

  // Fetch data from API
  const fetchOptions = async (inputValue) => {
    setIsLoading(true)
    try {
      const payload = {
        keyword: inputValue ? inputValue : '',
        filter: {},
        fields: [],
        page: inputValue ? 1 : 0,
      }
      const getCountryList = await getCountry(payload)
      if (getCountryList?.data?.value) {
        const newOptions = getCountryList?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
          ZONE: item.zone,
        }))
        setOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  // Handle input change and trigger API call
  const handleInputChange = (inputValue) => {
    if (inputValue.length > 2) {
      fetchOptions(inputValue)
    }
  }

  const handleSelectionChange = (COUNTRY) => {
    if (!COUNTRY) {
      fetchOptions() // Call the function to perform API request
      setZoneOptions([])
    } else {
      setSelectedCountry(COUNTRY.value)
    }
  }

  // Fetch data from API
  const fetchZoneOptions = async (inputValue) => {
    try {
      setIsLoading(true)
      const payload = {
        keyword: inputValue ? inputValue : '',
        filter: { country: selectedCountry },
        fields: [],
        page: inputValue ? 1 : 0,
      }
      const getZoneList = await getZone(payload)
      if (getZoneList?.data?.value) {
        const newOptions = getZoneList?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setZoneOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleZoneInputChange = (inputValue) => {
    if (inputValue.length > 2) {
      fetchStateOptions(inputValue)
    }
  }

  const handleZoneSelectionChange = (ZONE) => {
    if (!ZONE) {
      fetchZoneOptions() // Call the function to perform API request
    } else {
      setSelectedZone(ZONE.value)
    }
  }

  // Fetch data from API
  const fetchStateOptions = async (inputValue) => {
    setIsLoading(true)
    try {
      const payload = {
        keyword: inputValue ? inputValue : '',
        filter: { zone: selectedZone },
        fields: [],
        page: inputValue ? 1 : 0,
      }
      const getStateList = await getState(payload)
      if (getStateList?.data?.value) {
        const newOptions = getStateList?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setStateOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleStateInputChange = (inputValue) => {
    if (inputValue.length > 2) {
      fetchStateOptions(inputValue)
    }
  }

  const handleStateSelectionChange = (STATE) => {
    if (!STATE) {
      fetchStateOptions() // Call the function to perform API request
    } else {
      setSelectedState(STATE.value)
    }
  }

  // Fetch data from API
  const fetchDistrictOptions = async (inputValue) => {
    try {
      setIsLoading(true)
      const payload = {
        keyword: inputValue ? inputValue : '',
        filter: { state: selectedState },
        fields: [],
        page: inputValue ? 1 : 0,
      }
      const getDistrictList = await getDistrict(payload)
      if (getDistrictList?.data?.value) {
        const newOptions = getDistrictList?.data?.data?.results.map((item) => ({
          label: item.name,
          value: item._id,
        }))
        setDistrictOptions(newOptions)
      }
    } catch (error) {
      console.error('Error fetching options:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleDistrictInputChange = (inputValue) => {
    if (inputValue.length > 2) {
      fetchDistrictOptions(inputValue)
    }
  }

  const handleDistrictSelectionChange = (DISTRICT) => {
    if (!DISTRICT) {
      fetchOptions() // Call the function to perform API request
    }
  }

  useEffect(() => {
    fetchOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchZoneOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry])

  useEffect(() => {
    fetchStateOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedZone])

  useEffect(() => {
    fetchDistrictOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedState])

  const changeBranchStatus = () => {
    setBranchStatus(!isBranchActive)
  }

  const changeBranchBillable = () => {
    setBranchBillable(!isBranchBillable)
  }

  /**
   * Handles the form submission for creating a new district.
   *
   * @async
   * @function onFormSubmit
   * @param {Object} formData - The form data containing the district details.
   * @param {string} formData.country - The id of the country.
   * @param {string} formData.zone - The id of the zone.
   * @param {string} formData.state - The id of the state.
   * @param {string} formData.state - The id of the district.
   * @param {string} formData.name - The name of the city.
   * @param {string} formData.stdCode - The stdCode of the city.
   * @param {string} formData.timezone - The timezone of the city.
   * @returns {Promise<void>} A promise that resolves when the form submission is complete.
   */
  const onFormSubmit = async (formData) => {
    try {
      showSpinner()
      const apiUrl = apiConfig.CITY_CREATE
      const payload = {
        country: formData.COUNTRY?.value,
        zone: formData.ZONE?.value,
        state: formData.STATE?.value,
        district: formData.DISTRICT?.value,
        name: formData.CITY,
        stdCode: formData.STD_CODE,
        timezone: parseFloat(formData.TIME_ZONE?.value) || '',
        _id: id ? id : undefined,
      }

      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.value) {
        addToast({
          title: `City "${formData.CITY}" ${
            id ? 'edited' : 'created'
          } successfully.`,
          type: 'success',
        })
        navigate(`/locations/city-list`)
      } else {
        addToast({
          title: `City creation failed.`,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  /**
   * Fetches the details of a specific district and sets the form values.
   *
   * @async
   * @function getDistrictDetails
   * @returns {Promise<void>} A promise that resolves when the district details are fetched and the form values are set.
   */
  const getDistrictDetails = async () => {
    try {
      showSpinner()
      const apiUrl = apiConfig.CITY_DETAIL.replace(':id', id)
      const payload = {
        _id: id,
        checkRoles: false,
        accessOf: ['cityEdit'],
        accessToken: 'TRqQbw5z6Svuh9CA',
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response.data === 'Access Denied') {
        addToast({
          title: 'Access Denied',
          type: 'error',
        })
        navigate(`/locations/city-list`)
      } else if (response?.data?.value) {
        setValue(
          'COUNTRY',
          {
            label: response?.data?.data?.district?.state?.zone?.country?.name,
            value: response?.data?.data?.district?.state?.zone?.country?._id,
          } || {}
        )
        setSelectedCountry(
          response?.data?.data?.district?.state?.zone?.country?._id
        )
        fetchOptions(response?.data?.data?.district?.state?.zone?.country?.name)
        setValue(
          'ZONE',
          {
            label: response?.data?.data?.district?.state?.zone?.name,
            value: response?.data?.data?.district?.state?.zone?._id,
          } || {}
        )
        setSelectedZone(response?.data?.data?.district?.state?.zone?._id)
        fetchZoneOptions(response?.data?.data?.district?.state?.zone?.name)
        setValue(
          'STATE',
          {
            label: response?.data?.data?.district?.state?.name,
            value: response?.data?.data?.district?.state?._id,
          } || {}
        )
        setSelectedState(response?.data?.data?.district?.state?._id)
        fetchStateOptions(response?.data?.data?.district?.state?.name)
        setValue(
          'DISTRICT',
          {
            label: response?.data?.data?.district?.name,
            value: response?.data?.data?.district?._id,
          } || {}
        )
        fetchDistrictOptions(response?.data?.data?.district?.name)
        setValue('CITY', response?.data?.data?.name || '')
        setValue('STD_CODE', response?.data?.data?.stdCode || '')
        if (response?.data?.data?.timezone) {
          const result = timezoneOptions.find((option) => {
            return parseFloat(option.value) === response?.data?.data?.timezone
          })
          setSelectedTimezone(result)
        }
      } else {
        addToast({
          title: 'Some Error Occured',
          type: 'error',
        })
      }
    } catch (error) {
      console.error('Error verifying token:', error)
    } finally {
      hideSpinner()
    }
  }

  const handleCancel = () => {
    navigate(`/locations/city-list`)
  }

  useEffect(() => {
    if (id) {
      getDistrictDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card extra={'w-full h-full pb-[35px] pt-[3px] pr-[28px] pl-[33px]'}>
      <div className="mt-7 grid grid-cols-2 gap-3">
        <div className="col-span-2 lg:col-span-1">
          <p className="text-xl font-bold text-navy-700">
            {id ? 'Edit' : 'Add'} Assignment
          </p>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <div className="flex w-full justify-end">
            <label className="mb-4 mr-2 mt-3 block text-sm font-bold">
              Status
            </label>
            <SwitchWithText
              textLeft="OFF"
              textRight="ON"
              checked={isBranchBillable}
              onChange={changeBranchBillable}
            />
          </div>
        </div>
      </div>
      {/* <div className="mt-[25px]">
        <p className="text-xl font-bold text-navy-700 dark:text-white">
     
          Add Assignment
          <label className="mb-4 mt-2 block text-sm font-bold">Status</label>
          <SwitchWithText
            textLeft="NO"
            textRight="YES"
            checked={isBranchBillable}
            onChange={changeBranchBillable}
          />
        </p>
      </div> */}

      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="grid grid-cols-1 gap-3">
          <div className="heading-sec">
            <div className="flex flex-wrap">
              {/* Heading */}
              <div className="w-full">
                <div className="heading-profile ul-clas">
                  <ul className="flex justify-end">
                    {/* Timeline Link (Shown if formData._id exists) */}
                    {/* {id && (
                      <li className="ml-2">
                        <a
                          href={`/timeline/${id}`}
                          className="btn btn-success temp-btnsuccess"
                        >
                          Timeline
                        </a>
                      </li>
                    )} */}
                    {/* {formData?._id && (
                      <li className="ml-2">
                        <a
                          href={`/timeline/${formData?._id}`}
                          className="btn btn-success temp-btnsuccess"
                        >
                          Timeline
                        </a>
                      </li>
                    )} */}

                    {/* Toggle Switch for Status */}
                    <li className="ml-2">
                      {/* <Toggle
                        checked={formData?.status}
                        onChange={handleStatusChange}
                        className="pull-right"
                      /> */}
                    </li>
                  </ul>
                </div>
              </div>

              {/* Clone From Section (Shown if formData._id is empty) */}
              {/* {!formData?._id && ( */}
              {!id && (
                <div className="w-1/4">
                  <div className="form-group">
                    <label className="mb-2 block text-sm font-bold">
                      Clone From <span className="text-red-500">*</span>
                    </label>
                    <Controller
                      name="COUNTRY"
                      control={control}
                      rules={{ required: 'Country is required' }}
                      render={({ field, fieldState: { error } }) => (
                        <Select
                          {...field}
                          options={options}
                          placeholder="Search and select..."
                          classNamePrefix="select"
                          isClearable
                          isLoading={isLoading}
                          onInputChange={handleInputChange}
                          noOptionsMessage={() => 'Type to search...'}
                          onChange={(COUNTRY) => {
                            field.onChange(COUNTRY)
                            handleSelectionChange(COUNTRY) // Call a function to handle API call
                            if (!COUNTRY) {
                              // Reset ZONE dropdown when COUNTRY is reset
                              setValue('ZONE', null) // Clear the ZONE field in the form
                              // Reset STATE dropdown when COUNTRY is reset
                              setValue('STATE', null) // Clear the STATE field in the form
                              // Reset DISTRICT dropdown when COUNTRY is reset
                              setValue('DISTRICT', null) // Clear the DISTRICT field in the form
                            }
                          }}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              borderColor: error
                                ? 'red'
                                : 'rgba(218, 222, 236, 1)', // Red border on error
                              minHeight: '44px', // Increase height
                              borderRadius: '0.75rem', // Border radius
                              boxShadow: state.isFocused
                                ? `0 0 0 1px ${
                                    error ? 'red' : 'rgba(218, 222, 236, 1)'
                                  }` // Red shadow on focus if error
                                : '',
                              '&:hover': {
                                borderColor: error
                                  ? 'red'
                                  : 'rgba(218, 222, 236, 1)', // Red border on hover if error
                              },
                            }),
                            placeholder: (base) => ({
                              ...base,
                              fontWeight: 'normal',
                              fontSize: '14px',
                              color: error ? 'red' : '#999', // Red placeholder color on error, default gray otherwise
                            }),
                          }}
                        />
                      )}
                    />
                    {errors.COUNTRY && (
                      <div className="error-message text-right text-red-500">
                        {errors.COUNTRY.message}
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* Clone Button (Shown if formData._id is empty) */}
              {/* {!formData?._id && ( */}
              {!id && (
                <div className="mt-5 w-full">
                  <button className="btn rounded bg-gray-500 px-4 py-2 text-white">
                    Clone
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="mt-7 grid grid-cols-2 gap-3">
            <div className="col-span-2 lg:col-span-1">
              <label className="mb-2 block text-sm font-bold">
                Company Name <span className="text-red-500">*</span>
              </label>
              <Controller
                name="COMPANYNAME"
                control={control}
                rules={{ required: 'Country is required' }}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    options={options}
                    placeholder="Search and select..."
                    classNamePrefix="select"
                    isClearable
                    isLoading={isLoading}
                    onInputChange={handleInputChange}
                    noOptionsMessage={() => 'Type to search...'}
                    onChange={(COUNTRY) => {
                      field.onChange(COUNTRY)
                      handleSelectionChange(COUNTRY) // Call a function to handle API call
                      if (!COUNTRY) {
                        // Reset ZONE dropdown when COUNTRY is reset
                        setValue('ZONE', null) // Clear the ZONE field in the form
                        // Reset STATE dropdown when COUNTRY is reset
                        setValue('STATE', null) // Clear the STATE field in the form
                        // Reset DISTRICT dropdown when COUNTRY is reset
                        setValue('DISTRICT', null) // Clear the DISTRICT field in the form
                      }
                    }}
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Red border on error
                        minHeight: '44px', // Increase height
                        borderRadius: '0.75rem', // Border radius
                        boxShadow: state.isFocused
                          ? `0 0 0 1px ${
                              error ? 'red' : 'rgba(218, 222, 236, 1)'
                            }` // Red shadow on focus if error
                          : '',
                        '&:hover': {
                          borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Red border on hover if error
                        },
                      }),
                      placeholder: (base) => ({
                        ...base,
                        fontWeight: 'normal',
                        fontSize: '14px',
                        color: error ? 'red' : '#999', // Red placeholder color on error, default gray otherwise
                      }),
                    }}
                  />
                )}
              />
              {errors.COUNTRY && (
                <div className="error-message text-right text-red-500">
                  {errors.COUNTRY.message}
                </div>
              )}
            </div>

            <div className="col-span-2 lg:col-span-1">
              <label className="mb-2 block text-sm font-bold">
                Branch Office <span className="text-red-500">*</span>
              </label>
              <Controller
                name="BRANCHOFFICE"
                control={control}
                rules={{ required: 'Country is required' }}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    options={options}
                    placeholder="Search and select..."
                    classNamePrefix="select"
                    isClearable
                    isLoading={isLoading}
                    onInputChange={handleInputChange}
                    noOptionsMessage={() => 'Type to search...'}
                    onChange={(COUNTRY) => {
                      field.onChange(COUNTRY)
                      handleSelectionChange(COUNTRY) // Call a function to handle API call
                      if (!COUNTRY) {
                        // Reset ZONE dropdown when COUNTRY is reset
                        setValue('ZONE', null) // Clear the ZONE field in the form
                        // Reset STATE dropdown when COUNTRY is reset
                        setValue('STATE', null) // Clear the STATE field in the form
                        // Reset DISTRICT dropdown when COUNTRY is reset
                        setValue('DISTRICT', null) // Clear the DISTRICT field in the form
                      }
                    }}
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Red border on error
                        minHeight: '44px', // Increase height
                        borderRadius: '0.75rem', // Border radius
                        boxShadow: state.isFocused
                          ? `0 0 0 1px ${
                              error ? 'red' : 'rgba(218, 222, 236, 1)'
                            }` // Red shadow on focus if error
                          : '',
                        '&:hover': {
                          borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Red border on hover if error
                        },
                      }),
                      placeholder: (base) => ({
                        ...base,
                        fontWeight: 'normal',
                        fontSize: '14px',
                        color: error ? 'red' : '#999', // Red placeholder color on error, default gray otherwise
                      }),
                    }}
                  />
                )}
              />
              {errors.COUNTRY && (
                <div className="error-message text-right text-red-500">
                  {errors.COUNTRY.message}
                </div>
              )}
            </div>

            <div className="col-span-2 lg:col-span-1">
              <label className="mb-2 block text-sm font-bold">
                Nature of Survey <span className="text-red-500">*</span>
              </label>
              <Controller
                name="NATURESURVEY"
                control={control}
                rules={{ required: 'Country is required' }}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    options={options}
                    placeholder="Search and select..."
                    classNamePrefix="select"
                    isClearable
                    isLoading={isLoading}
                    onInputChange={handleInputChange}
                    noOptionsMessage={() => 'Type to search...'}
                    onChange={(COUNTRY) => {
                      field.onChange(COUNTRY)
                      handleSelectionChange(COUNTRY) // Call a function to handle API call
                      if (!COUNTRY) {
                        // Reset ZONE dropdown when COUNTRY is reset
                        setValue('ZONE', null) // Clear the ZONE field in the form
                        // Reset STATE dropdown when COUNTRY is reset
                        setValue('STATE', null) // Clear the STATE field in the form
                        // Reset DISTRICT dropdown when COUNTRY is reset
                        setValue('DISTRICT', null) // Clear the DISTRICT field in the form
                      }
                    }}
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Red border on error
                        minHeight: '44px', // Increase height
                        borderRadius: '0.75rem', // Border radius
                        boxShadow: state.isFocused
                          ? `0 0 0 1px ${
                              error ? 'red' : 'rgba(218, 222, 236, 1)'
                            }` // Red shadow on focus if error
                          : '',
                        '&:hover': {
                          borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Red border on hover if error
                        },
                      }),
                      placeholder: (base) => ({
                        ...base,
                        fontWeight: 'normal',
                        fontSize: '14px',
                        color: error ? 'red' : '#999', // Red placeholder color on error, default gray otherwise
                      }),
                    }}
                  />
                )}
              />
              {errors.COUNTRY && (
                <div className="error-message text-right text-red-500">
                  {errors.COUNTRY.message}
                </div>
              )}
            </div>

            {!id && (
              <div className="col-span-2 lg:col-span-1">
                <label className="mb-2 block text-sm font-bold">
                  Owner <span className="text-red-500">*</span>
                </label>
                <Controller
                  name="Owner"
                  control={control}
                  rules={{ required: 'Owner is required' }}
                  render={({ field, fieldState: { error } }) => (
                    <Select
                      {...field}
                      options={options}
                      placeholder="Owner"
                      disabled={true}
                      classNamePrefix="select"
                      isClearable
                      isLoading={isLoading}
                      onInputChange={handleInputChange}
                      noOptionsMessage={() => 'Type to search...'}
                      onChange={(COUNTRY) => {
                        field.onChange(COUNTRY)
                        handleSelectionChange(COUNTRY) // Call a function to handle API call
                        if (!COUNTRY) {
                          // Reset ZONE dropdown when COUNTRY is reset
                          setValue('ZONE', null) // Clear the ZONE field in the form
                          // Reset STATE dropdown when COUNTRY is reset
                          setValue('STATE', null) // Clear the STATE field in the form
                          // Reset DISTRICT dropdown when COUNTRY is reset
                          setValue('DISTRICT', null) // Clear the DISTRICT field in the form
                        }
                      }}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Red border on error
                          minHeight: '44px', // Increase height
                          borderRadius: '0.75rem', // Border radius
                          boxShadow: state.isFocused
                            ? `0 0 0 1px ${
                                error ? 'red' : 'rgba(218, 222, 236, 1)'
                              }` // Red shadow on focus if error
                            : '',
                          '&:hover': {
                            borderColor: error
                              ? 'red'
                              : 'rgba(218, 222, 236, 1)', // Red border on hover if error
                          },
                        }),
                        placeholder: (base) => ({
                          ...base,
                          fontWeight: 'normal',
                          fontSize: '14px',
                          color: error ? 'red' : '#999', // Red placeholder color on error, default gray otherwise
                        }),
                      }}
                    />
                  )}
                />
                {errors.COUNTRY && (
                  <div className="error-message text-right text-red-500">
                    {errors.COUNTRY.message}
                  </div>
                )}
              </div>
            )}

            {id && (
              <div className="col-span-2 lg:col-span-1">
                <InputField
                  extra="w-full"
                  label="Owner"
                  id="owner"
                  type="text"
                  disabled={true}
                  placeholder="Owner"
                  registrationProps={register('OWNER', {
                    required: 'OWNER code is required',
                  })}
                  isFieldRequired={true}
                  state={errors.STD_CODE && 'error'}
                />
                <div className="error-message text-right">
                  {errors.OWNER?.message}
                </div>
              </div>
            )}

            <div className="col-span-2 lg:col-span-1">
              <label className="mb-2 block text-sm font-bold">
                Type of Claim<span className="text-red-500">*</span>
              </label>

              <SwitchWithText
                textLeft="Survey"
                textRight="Desktop"
                checked={isBranchBillable}
                onChange={changeBranchBillable}
                afterWidth={'after:w-[75px]'}
              />
            </div>

            <div className="col-span-2 lg:col-span-1">
              <label className="mb-2 block text-sm font-bold">
                Zone <span className="text-red-500">*</span>
              </label>
              <Controller
                name="ZONE"
                control={control}
                rules={{ required: 'Zone is required' }}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    options={zoneOptions}
                    placeholder="Search and select..."
                    classNamePrefix="select"
                    isClearable
                    isLoading={isLoading}
                    onInputChange={handleZoneInputChange}
                    noOptionsMessage={() => 'Type to search...'}
                    onChange={(ZONE) => {
                      field.onChange(ZONE)
                      handleZoneSelectionChange(ZONE) // Call a function to handle API call
                      if (!ZONE) {
                        // Reset STATE dropdown when ZONE is reset
                        setValue('STATE', null) // Clear the STATE field in the form
                        // Reset DISTRICT dropdown when ZONE is reset
                        setValue('DISTRICT', null) // Clear the DISTRICT field in the form
                      }
                    }}
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Red border on error
                        minHeight: '44px', // Increase height
                        borderRadius: '0.75rem', // Border radius
                        boxShadow: state.isFocused
                          ? `0 0 0 1px ${
                              error ? 'red' : 'rgba(218, 222, 236, 1)'
                            }` // Red shadow on focus if error
                          : '',
                        '&:hover': {
                          borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Red border on hover if error
                        },
                      }),
                      placeholder: (base) => ({
                        ...base,
                        fontWeight: 'normal',
                        fontSize: '14px',
                        color: error ? 'red' : '#999', // Red placeholder color on error, default gray otherwise
                      }),
                    }}
                  />
                )}
              />
              {errors.ZONE && (
                <div className="error-message text-right text-red-500">
                  {errors.ZONE.message}
                </div>
              )}
            </div>

            <div className="col-span-2 lg:col-span-1">
              <label className="mb-2 block text-sm font-bold">
                State <span className="text-red-500">*</span>
              </label>
              <Controller
                name="STATE"
                control={control}
                rules={{ required: 'State is required' }}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    options={stateOptions}
                    placeholder="Search and select..."
                    classNamePrefix="select"
                    isClearable
                    isLoading={isLoading}
                    onInputChange={handleStateInputChange}
                    noOptionsMessage={() => 'Type to search...'}
                    onChange={(STATE) => {
                      field.onChange(STATE)
                      handleStateSelectionChange(STATE) // Call a function to handle API call
                      if (!STATE) {
                        // Reset DISTRICT dropdown when STATE is reset
                        setValue('DISTRICT', null) // Clear the DISTRICT field in the form
                      }
                    }}
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Red border on error
                        minHeight: '44px', // Increase height
                        borderRadius: '0.75rem', // Border radius
                        boxShadow: state.isFocused
                          ? `0 0 0 1px ${
                              error ? 'red' : 'rgba(218, 222, 236, 1)'
                            }` // Red shadow on focus if error
                          : '',
                        '&:hover': {
                          borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Red border on hover if error
                        },
                      }),
                      placeholder: (base) => ({
                        ...base,
                        fontWeight: 'normal',
                        fontSize: '14px',
                        color: error ? 'red' : '#999', // Red placeholder color on error, default gray otherwise
                      }),
                    }}
                  />
                )}
              />
              {errors.STATE && (
                <div className="error-message text-right text-red-500">
                  {errors.STATE.message}
                </div>
              )}
            </div>

            <div className="col-span-2 lg:col-span-1">
              <label className="mb-2 block text-sm font-bold">
                District <span className="text-red-500">*</span>
              </label>
              <Controller
                name="DISTRICT"
                control={control}
                rules={{ required: 'District is required' }}
                render={({ field, fieldState: { error } }) => (
                  <Select
                    {...field}
                    options={districtOptions}
                    placeholder="Search and select..."
                    classNamePrefix="select"
                    isClearable
                    isLoading={isLoading}
                    onInputChange={handleDistrictInputChange}
                    noOptionsMessage={() => 'Type to search...'}
                    onChange={(DISTRICT) => {
                      field.onChange(DISTRICT)
                      handleDistrictSelectionChange(DISTRICT) // Call a function to handle API call
                    }}
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Red border on error
                        minHeight: '44px', // Increase height
                        borderRadius: '0.75rem', // Border radius
                        boxShadow: state.isFocused
                          ? `0 0 0 1px ${
                              error ? 'red' : 'rgba(218, 222, 236, 1)'
                            }` // Red shadow on focus if error
                          : '',
                        '&:hover': {
                          borderColor: error ? 'red' : 'rgba(218, 222, 236, 1)', // Red border on hover if error
                        },
                      }),
                      placeholder: (base) => ({
                        ...base,
                        fontWeight: 'normal',
                        fontSize: '14px',
                        color: error ? 'red' : '#999', // Red placeholder color on error, default gray otherwise
                      }),
                    }}
                  />
                )}
              />
              {errors.DISTRICT && (
                <div className="error-message text-right text-red-500">
                  {errors.DISTRICT.message}
                </div>
              )}
            </div>

            <div className="col-span-2 lg:col-span-1">
              <InputField
                extra="w-full"
                label="City"
                id="city"
                type="text"
                placeholder="Enter city name"
                registrationProps={register('CITY', {
                  required: 'City is required',
                })}
                isFieldRequired={true}
                state={errors.CITY && 'error'}
              />
              <div className="error-message text-right">
                {errors.CITY?.message}
              </div>
            </div>

            <div className="col-span-2 lg:col-span-1">
              <InputField
                extra="w-full"
                label="Std Code"
                id="std_code"
                type="text"
                placeholder="Enter STD code name"
                registrationProps={register('STD_CODE', {
                  required: 'STD code is required',
                })}
                isFieldRequired={true}
                state={errors.STD_CODE && 'error'}
              />
              <div className="error-message text-right">
                {errors.STD_CODE?.message}
              </div>
            </div>

            <div className="col-span-2 lg:col-span-1">
              <label className="mb-2 block text-sm font-bold">Timezone</label>
              <Controller
                name="TIME_ZONE"
                control={control}
                render={({ field }) => (
                  <TimezoneSelect
                    {...field}
                    value={selectedTimezone}
                    onChange={(TIME_ZONE) => {
                      field.onChange(TIME_ZONE) // Updates form state
                      setSelectedTimezone(TIME_ZONE) // Updates local state
                    }}
                    isClearable
                    options={timezoneOptions} // Pass the timezone options here
                    placeholder="Search and select..."
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        borderColor: 'rgba(218, 222, 236, 1)',
                        minHeight: '44px', // Increase height
                        borderRadius: '0.75rem', // Border radius
                        boxShadow: state.isFocused
                          ? `0 0 0 1px rgba(218, 222, 236, 1)`
                          : '',
                        '&:hover': {
                          borderColor: 'rgba(218, 222, 236, 1)',
                        },
                      }),
                      placeholder: (base) => ({
                        ...base,
                        fontWeight: 'normal',
                        fontSize: '14px',
                        color: '#999', // default gray otherwise
                      }),
                    }}
                  />
                )}
              />
            </div>
          </div>
          {/* Button aligned to the right */}
          <div className="flex w-full justify-end">
            <button
              className={`mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200
          hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 md:text-base`}
              type="submit"
            >
              <span>{id ? 'Update' : 'Save'}</span>
            </button>

            <button
              className={`ml-2 mt-2 w-40 cursor-pointer items-center justify-center rounded-2xl bg-gray-400 p-3 text-sm text-white transition duration-200
          hover:cursor-pointer hover:bg-gray-600 active:bg-gray-700 md:text-base`}
              type="button"
              onClick={handleCancel}
            >
              <span>Cancel</span>
            </button>
          </div>
        </div>
      </form>
    </Card>
  )
}

export default AddAssignment
